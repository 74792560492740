.loginBtn {
  background-color: rgb(10, 197, 168);
  border-color: rgb(10, 197, 168);
}

#loginBtn,
#signBtn:hover {
  color:rgba(242,84,45);
  border-color:rgba(242,84,45);
  background-color: white;
}


.overlayPic > .img-container {
  position: static;
  display: inline-block;
}
.overlayPic > .img-container .overlay {
  position: static;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(48, 48, 48, 0.571);
  transition: opacity 1000ms ease-in-out;
}
.overlayPic > .img-container:hover .overlay {
  opacity: 3;
}
.overlayPic > .img-container > .overlay span {
  position: static;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}


#textarea {
  min-height: 100%;
}
.footerStyle p {
  color: #fff;
}

h1,
p,
a,
button {
  font-family: 'Poppins';
}

.img-wrapper {
  overflow: hidden;
}
img.hove-zoom {
  transition: transform 3s ease;
  transform-origin: 50% 50%;
}
img.hover-zoom:hover {
  transform: scale(1.25);
}

.loginBtn {
  background-color: rgb(10, 197, 168);
  border-color: rgb(10, 197, 168);
}


.prodsize {
  max-width: 100%;
  width: 80%;
  height: auto;
  padding-left: 6%;

}
.minH {
  min-height: 400px;
}



.overlayPic > .img-container {
  position: relative;
  display: inline-block;
}
.overlayPic > .img-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(48, 48, 48, 0.571);
  transition: opacity 1000ms ease-in-out;
}
.overlayPic > .img-container:hover .overlay {
  opacity: 3;
}
.overlayPic > .img-container > .overlay span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}


#formBtn {
  background-color: rgb(237, 237, 237);
  color: rgb(50, 50, 50);
  border-color: transparent;
  font-family: 'Tahoma';

  font-weight:600;
  border-radius: 25;
  text-align: center;
  align-items: center;
  width: 25%;
  font-size: 90%;
}

#formBtn:hover {
  
  border-color: rgba(242,84,45);;
  background-color: rgba(242,84,45);;
}
#textarea {
  min-height: 100%;
}
.footerStyle p {
  color: #fff;
}

/* app */


#produtos {
  color: rgba(242,84,45);
  font-family:'Poppins';
  font-size: 300%;
  font-weight:900;
  margin-top: 3em;
  margin-bottom: 1.5em;

}
#produtos::after{
  content: "ÕES";
  text-decoration: underline solid rgba(242,84,45);
  text-underline-offset: 20%;
  }
#produtosTd {
  color: rgba(242,84,45);
  font-family:'Poppins';
  text-underline-offset: 55%;
  font-size: 250%;
  font-weight: bold;
  margin-top: 4em;

  
}
#produtosTd::after{
  content: "lógicas";
  text-decoration: underline solid rgba(242,84,45);
  text-underline-offset: 20%;
  }

@media (max-width: 600px) {
  #produtos {
    font-size: 200%;
    font-weight:800;
    margin-top: 1em;
    margin-bottom: 1em;
  }
 
    #produtosTd {
      font-size: 200%;
      font-weight:800;
      margin-top: 4em;
      margin-bottom: 2m;
    }
}
@media (max-width: 450px) {
  #produtos {
    font-size: 160%;
  font-weight:800;
  margin-top: 2em;
  margin-bottom: 1em;
  }
  
  #produtosTd {
    font-size: 160%;
    font-weight:800;
    margin-top: 4em;
    margin-bottom: 2em;
  }
}

/*header*/
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}
.header {
  align-items: center;
  justify-items: center;
  text-align: center;
  box-shadow: 2px 2px 2px #888888;
  background-color: white;


}

.row{
 margin-right: 15%;
 margin-left: 15%;

  align-items: center;
  justify-items: center;
  text-align: center;
}

.nav {
  display: grid;
  grid-template-columns: repeat(4, auto);
  align-items: center;
  justify-items: center;
  text-align: center;


}

.nav > div{
 width:100%
}
.nav-link{
  color: rgba(242,84,45);
  font-family:'Poppins';
  font-weight:800;
  font-size: 140%;
  margin-top: 5%;

}
.nav-link.active{
  color: rgb(0,87,255);
 
}
.nav-link.hover{
  color: rgb(200,224,254);
 
}


.nav-div-active{
  
  border-top: rgb(0,87,255) solid 4px ;


}
.nav-div-hover{
  
  border-top: rgb(200,224,254)solid 4px ;


}
.logo{
  width: 75%;
}

.sideMenu {
  display: none;
}
@media (max-width: 1000px) {
  .header {
    
    padding-left: 0%;
    padding-right: 0%;
  }
  .logo{
    width: 50%;
    margin-right: 40%;
    margin-bottom: 2%;
  }
  .row{
    margin-right: 0%;
    margin-left: 0%;
   
   }
  .nav {
    grid-template-rows: repeat(4, auto);
    grid-template-columns: none;
    height: 100%;
    width: 100%;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    background-color: white; /* Black fallback color */

   
  }
  .nav > div{
    width:40%
   }
  .sideMenu {
    display: block;
    padding-left: 15%;
    border-color: transparent;
    background-color: transparent;
    color: rgba(242,84,45);
    font-size:300% ;

  }
 
}
@media (max-width: 600px) {
  .logo{

    margin-right: 40%;
    margin-bottom: 2%;

  }
  .sideMenu {
  
    font-size:170% ;
   

  }
 
  
}

@media (max-width: 300px) {
  .logo{

    margin-right: 40%;
    margin-bottom: 2%;

  }
  .sideMenu {
  
    font-size:170% ;
    padding-left: 0%;
    padding-right: 6%;
  }
 
  
}
.NavAnimation-enter {
  opacity: 1;
  transform: scale(0.5);
}
.NavAnimation-enter-active {
  opacity: 2;
  transform: translateX(0);
  transition: opacity 250ms, transform 250ms;
}
.NavAnimation-exit {
  opacity: 2;
}
.NavAnimation-exit-active {
  opacity: 1;
  transform: scale(0.5);
  transition: opacity 250ms, transform 250ms;
}

/* carousel */
.Carrousel {
  margin-top: 5em;
  max-width: 100%;
  width: 95%;
  height: auto;

}
@media (max-width: 1000px) {
  .Carrousel {
    margin-top: 8em;
    max-width: 100%;
    width: 100%;
  
  
  }
}
@media (max-width: 800px) {
  .Carrousel {
    margin-top: 5em;
    width: 100%;
 
    
  }
  
}
@media (max-width: 600px) {
  .Carrousel {
    margin-top: 4em;
    width: 100%;
 
    
  }
  
}
@media (max-width: 300px) {
  .Carrousel {
    margin-top: 3em;
    width: 100%;
  
    
  }
  
}
/* album */
.cardProd {
  width: 28%;
  cursor: pointer;
  margin-right: 2%;
  margin-bottom: 2%;
}

.card {
  border: 6px;
  overflow: hidden;
  position: static;
 
}
.card-border {
  border: 6px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  overflow: hidden;
  position: static;
}

.card-Body{
  border: 6px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: rgb(212, 212, 212);
}
.card > div > img {
  transition: transform 3s ease;
  transform-origin: 50% 50%;
  position: static;
}

.card > div > img:hover {
  transform: scale(1.2);
  width: 100%;
  position: static;
}
.cardTitle {
  font-size: 120%;
  font-weight:700;
}
@media (max-width: 1000px) {
  .cardProd {
    width: 50%;
    margin: 0%;
   
  }
  .cardTitle{
    font-size: 
    90%;
  
  }
}
@media (max-width: 600px) {
  .cardProd {
    width: 75%;
    margin-left: 12%;
    
  }
  .cardTitle{
    font-size: 
    90%;
  
  }
}

#verProd {
  background-color:rgb(212, 212, 212);
  border: 2px solid rgb(212, 212, 212);
  font-family:'Poppins';
  font-size: 120%;
  color: black;
  padding-right: 4%;
  padding-left: 4%;
}
#verProd:hover {
  color:rgba(242,84,45);
  border-color:rgba(242,84,45);
  background-color: white;
}

@media (max-width: 1000px) {
  #verProd {
    font-size: 100%;
    padding-right: 6%;
  padding-left: 6%;
  }
}

/* Sobre */
.borderColor{
  border: 10px solid red;
}
#sobre{
  text-align: center;

}
#sobre p{
  font-family: 'Poppins';
  font-size: 120%;
  text-align: left;
  color:rgba(0,58,150);
  width: 75%;
}
#sobre h1{
  font-family: 'Poppins';
  font-size: 280%;
  font-weight: 800;
  color:rgba(242,84,45);
  margin-bottom: 5%;
  text-align: left;
}
#sobre h1::after{
  content: "ósito";
  text-decoration: underline 5px rgba(242,84,45);
  text-underline-offset: 15%;
  }

#sobre img{
  width: 40%;
  border-top-left-radius: 180px;
  border-bottom-right-radius: 180px;
  border-bottom-left-radius: 80px;
  border-top-right-radius: 80px;
}
@media (max-width: 1000px) {
  

  #sobre{
    width:100%;
   
  }
}

  @media (max-width: 800px) {
    #sobre p{
     
      width: 100%;
      font-size: 140%;
    }
    #sobre h1{
  
      font-size: 170%;
      font-weight: bolder;
      text-align:left;
    }
    #sobre img{
        display: none;
      
        
    }
    #sobre{
      width:100%;
     

    }
  }
  
@media (max-width: 400px) {
  #sobre p{
   
    width: 100%;
    font-size: 130%;
  }
  #sobre h1{

    font-size: 160%;
    font-weight: bolder;
    text-align:left;
   
  }

  
}





/* insta feed */

#instaFeed{
  text-align: center;
  width: 70%;

}
.instaIcon{
  color:rgba(0,58,150);
  width: 9%;
padding-top: 2.1%;
padding-right: 2%;
}

.insta-h1{
  font-size: 280%;
  font-weight: bolder;
  font-family: 'Poppins';

  color:rgba(0,58,150);
}

@media (max-width: 1000px) {
  #instaFeed{
  
    width: 100%;
  }
  .instaIcon{
    padding-top: 4%;
    width: 10%;
  }
  .insta-h1{

    font-size: 200%;
   
  }
  #instaFeed button{
    margin-top: 15%;

  }
  .instaCard{
    cursor: "pointer";
    width: 60%
  }
}
@media (max-width: 700px) {
  #instaFeed{
  
    width: 100%;
  }
  .instaIcon{
    padding-top: 6%;
    padding-right: 2%;
    width: 10%;
  }
  .insta-h1{

    font-size: 170%;
   
  }
  #instaFeed button{
    margin-top: 15%;

  }
  .instaCard{
    cursor: "pointer";
    width: 60%
  }

}
@media (max-width: 600px) {
  #instaFeed{
  
    width: 100%;
  }
  .instaIcon{
    padding-top: 5%;
    padding-right: 2%;
    width: 15%;
  }
  .insta-h1{

    font-size: 160%;
   
  }
  #instaFeed button{
    margin-top: 15%;

  }
  .instaCard{
    cursor: "pointer";
    width: 75%
  }
}
@media (max-width: 500px) {
  #instaFeed{
  
    width: 100%;
  }
  .instaIcon{
    padding-top: 10%;
    padding-right: 2%;
    width: 20%;
  }
  .insta-h1{

    font-size: 160%;
   
  }
  #instaFeed button{
    margin-top: 15%;

  }
  .instaCard{
    cursor: "pointer";
    width: 100%,
  }
}

@media (max-width: 400px) {
  #instaFeed{
  
    width: 100%;
  }
  .instaIcon{
    padding-top: 12%;
    padding-right: 2%;
    width: 25%;
  }
  .insta-h1{

    font-size: 150%;
   
  }
  #instaFeed button{
    margin-top: 15%;

    
  }
  .instaCard{
    cursor: "pointer";
    width: 100%,
  }
  
}

/* contate1  */
.contactForm{
  width: 100%;
  text-align: center;
  
}
.contactForm h4 {
  display: block;
  text-align: left;
  font-family: 'Poppins';
  font-size: 200%;
  color:rgba(0,87,225);
}
.contactForm p {
  display: block;
  text-align: left;
  font-family: 'Monserrat';
  font-size: 140%;
  
}
.Contate-nos p{
  width: 80%;
  
}
.contactForm label {
  display: block;
  text-align: left;
}
.Contate-nos{
  width: 70%;
  text-align: center;
  padding-left: 15%;
}
.form1{
  width: 80%;
  text-align: left;
  padding-left: 3%;
}
.info{
  padding-right: 10%;
}
#contateLabel {
  color: rgba(242,84,45);
  font-family:'Poppins';
  font-size: 300%;
  font-weight: bolder;
  margin-top: 3em;
  margin-bottom: 1.5em;

}
#contateLabel::after{
  content: "osco!";
  text-decoration: underline solid rgba(242,84,45);
  text-underline-offset: 20%;
  }
@media (max-width: 900px) {
  .contactForm h4 {
  
    font-size: 160%;
  
  }
  .contactForm p {

    font-size: 130%;
    
  }
  #contateLabel {

    font-size: 300%;
 
  
  }
}


@media (max-width: 400px) {
  .contactForm h4 {
  
    font-size: 160%;
  
  }
  .contactForm p {

    font-size: 120%;
    
  }
  #contateLabel {

    font-size: 200%;
 
  
  }


}


/* Contate 2 */

.cncForm {
  background-color: rgba(0,58,150);
  width: 100%;
}

.cncForm h1 {
 font-style: 'Poppins';
 font-weight: 900;
font-size: 250%;
}
.form-label {
  display: block;
  font-family: 'Georgia';
  font-style: italic;
  text-align: left;
  color: white;
}
.form-label2 {
  display: block;
  font-family: 'Georgia';
  font-style: italic;
  text-align: left;
  color: black;
}

.noP{
   margin: 0%;
  width: 100%;
 
}

@media (max-width: 600px) {
  .cncForm h1 {
  margin-top: 10%;
   font-size: 200%;
   }

}

@media (max-width: 400px) {
  .cncForm h1 {
  margin-top: 10%;
   font-size: 160%;
   }


}
@media (max-width: 300px) {
  .cncForm h1 {
  margin-top: 10%;
   font-size: 140%;
   }


}

/* Modal */
#modalTitle{
  font-family: 'Poppins';
  font-size: 200%;
  font-weight: 800;
  color:rgba(242,84,45);
  margin-bottom: 5%;
  text-align: left;
}
#modalText{
  font-family: 'Poppins';
  font-size: 120%;
  text-align: left;
  color:rgba(0,58,150);

}

#closeBtn {
  background-color: rgb(237, 237, 237);
  color: rgb(50, 50, 50);
  border-color: transparent;
  font-family: 'Tahoma';
  font-weight:600;
  border-radius: 25;
  text-align: center;
  align-items: center;
  width: 10%;
  font-size: 90%;
}

#closeBtn:hover {
  
  border-color: rgba(242,84,45);;
  background-color: rgba(242,84,45);;
}

@media (max-width: 1000px) {
  #closeBtn{
    width: 30%;
   }


}





/* fadding */
.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 1s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
  
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
  
}

.fade-in-left-section {

  opacity: 0;
  transform: translateY(20vh);
  transform: translate3d(-100%, 0, 0); /* ADDED - start x position as -50px */
  
  transition: opacity 1.09s ease-out, transform 1.09s ease-out;
 
}
.fade-in-left-section.is-visible {
  opacity: 1;
  transform: translate3d(0, 0, 0);
  
}
.overflow{
  overflow-x:hidden

}

.fade-in-right-section {

  opacity: 0;
  transform: translateY(20vh);
  transform: translate3d(100%, 0, 0);
 
  transition: opacity 1.09s ease-out, transform 1.09s ease-out;
 
}
.fade-in-right-section.is-visible {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

/* Whatsapp icon */
.my-icon {
  position: fixed;

  background-color: transparent;
  right: 0;
  top: 90%;
  border: 0;
  display: flex;
  justify-content: right;
}

.wIcon{
  width: 15%  ;
  margin-right: 10%;
}

@media (max-width: 600px) {
  .wIcon{
    width: 10%  ;
    margin-right: 5%;
  }

}